import { render, staticRenderFns } from "./InsurerListSideMenu.vue?vue&type=template&id=4e3d9597&scoped=true"
import script from "./InsurerListSideMenu.vue?vue&type=script&lang=js"
export * from "./InsurerListSideMenu.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4e3d9597",
  null
  
)

export default component.exports